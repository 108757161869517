import React from 'react'
import { Card as CardComponent } from 'theme-ui'
import { Layout, Stack, Main, Sidebar } from '@layout'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Sticky from '@components/Sticky'
import Seo from '@widgets/Seo'
import AuthorCompact from '@widgets/AuthorCompact'
import Categories from '@widgets/Categories'
import {
  PostHead,
  PostImage,
  PostBody,
  PostComments,
  PostTagsShare,
  PostFooter
} from '@widgets/Post'
import { useBlogCategories } from '@helpers-blog'
import AuthorExpanded from '@widgets/AuthorExpanded'

const Post = ({
  data: { post, tagCategoryPosts, tagPosts, categoryPosts, previous, next },
  ...props
}) => {
  const relatedPosts = [
    ...(tagCategoryPosts ? tagCategoryPosts.nodes : []),
    ...(tagPosts ? tagPosts.nodes : []),
    ...(categoryPosts ? categoryPosts.nodes : [])
  ]
  const { pageContext: { services = {}, siteUrl } = {} } = props
  const categories = useBlogCategories()
  console.log(post.category.name)

  if (post.category.name !== 'Projects') {
    return (
      <Layout {...props}>
        <Seo {...post} siteUrl={siteUrl} />
        <Divider />
        <Stack effectProps={{ effect: 'fadeInDown' }}>
          <PostHead {...post} />
        </Stack>
        <Divider />
        <Stack effectProps={{ fraction: 0 }}>
          <Main>
            <CardComponent variant='paper'>
              <PostImage {...post} inCard />
              <PostBody {...post} />
              <PostTagsShare {...post} location={props.location} />
              {services.disqus && <PostComments {...post} />}
              <PostFooter {...{ previous, next }} />
            </CardComponent>
          </Main>
            <Sidebar>
            <AuthorCompact author={post.author} omitTitle />
            <Divider />
            <Categories categories={categories} />
            <Divider />
            {post.category && (
              <Sticky>
                <CardList
                  title='Related Posts'
                  nodes={relatedPosts}
                  variant='horizontal-aside'
                  omitMedia
                  omitCategory
                  limit={6}
                  distinct
                  aside
                />
              </Sticky>
            )}
          </Sidebar>
        </Stack>
      </Layout>
    )
  }
  else {
    return (
      <Layout {...props}>
        <Seo {...post} siteUrl={siteUrl} />
        <Divider space={3} />
        <Stack>
          <Main>
            <CardComponent variant='paper-lg'>
              <PostImage {...post} inCardLarge />
              <PostHead {...post} />
              <Divider line />
              <PostBody {...post} />
              <PostTagsShare {...post} location={props.location} />
              {services.disqus && <PostComments {...post} />}
            </CardComponent>
            <Divider />
            <AuthorExpanded author={post.author} />
            <Divider />
            {post.category && (
              <CardList
                title='Related Posts'
                nodes={relatedPosts}
                variant={['horizontal-md']}
                columns={[1, 2, 2, 2]}
                limit={6}
                distinct
              />
            )}
          </Main>
        </Stack>
      </Layout>
    )
  } 
}

export default Post
